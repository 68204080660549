import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components'


const Title = styled.h2`
  font-size: 2em;
  text-align: center;
  margin-top: 25px;
`

const Note = styled.p`
  text-align: center;
`

const ListItem = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const ExtraPakiet = styled.div`
  width: 100%;
  padding: 10px 20px;
  margin: auto;
  justify-items: center;
  /* background-color: red; */
`
const Item = styled.div`
  width: 100%;
  padding: 10px 20px;
  margin: 20px 10px;
  -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
  -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
  box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
  /* background: rgb(52,210,119);
  background: linear-gradient(180deg, rgba(52,210,119,0.3) 0%, rgba(83,0,13,0.5) 100%); */
  border: 5px double gray;

  h3{
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    span{
      color: #34D277;
      text-transform: lowercase;
    }
  }
  ul{
    text-align: left;
  }

  h5{
    font-size: 1.2em;
    text-align: right;
    text-decoration: underline;
  }
  
  ${({theme})=> theme.media.tablet}{
          width: 480px;        
      }
  
  div{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .img {
    /* border: 10px solid whitesmoke; */
    padding: 20px;
    margin: auto auto;
    width: 200px;
    
  }
`

const Pakiet = () => {
  return (
    <>
      <Title>Pakiety startowe</Title>
      <ListItem>
        <Item>
                <h3>Piccolo <span>319 zł*</span></h3>
                <br/>
                <h5>Na starcie</h5>
                <ul>
                    <li>Jedna ze specjalnie zaprojektowanych tras i w 100% przejechana przez Nas - km po km</li>
                    <li>System śledzenia online (wypożyczenie urządzenia GPS)</li>
                    <li>Pit-stop  - napoje, przekąski</li>
                    <li>Numer startowy</li>
                    <li>Pakiet zdjęć wykonany przez profesjonalny zespół</li>
                    <li>Spotkanie integracyjne przed startem z przekąskami</li>
                    <li>Pyszną niespodziankę na starcie</li>
                    <li>Pomiątkowa naszywka Ultra Race Roztocze</li>
                    <li>Torba Ultra Race Roztocze</li>
                    <li>Możliwość pozostawienia depozytu</li>
                    <li>Obsługę zawodów, możliwość korzystania z infrastruktury w bazie zawodów</li>
                </ul>
                <h5>Na mecie</h5>
                <ul>
                  <li>Pamiątkowy trofeum</li>
                  <li>Posiłek regeneracyjny</li>
                </ul>
                <h5>Dodatkowo pakiet możesz wzbogacić o</h5>
                <ul>
                  <li>skarpetki rowerowe URR (38 zł)</li>
                  <li>koszulka kolarską URR (319 zł)</li>
                  <li>t-shirt techniczny URR (108 zł)</li>
                </ul>
        </Item>
        <Item>
                <h3>Classico/Gigante <span>419 zł*</span></h3>
                <br/>
                <h5>Na starcie</h5>
                <ul>
                    <li>Jedna ze specjalnie zaprojektowanych tras i w 100% przejechana przez Nas - km po km</li>
                    <li>System śledzenia online (wypożyczenie urządzenia GPS)</li>
                    <li>Pit-stop  - napoje, przekąski, ciepły posiłek </li>
                    <li>Numer startowy</li>
                    <li>Pakiet zdjęć wykonany przez profesjonalny zespół</li>
                    <li>Spotkanie integracyjne przed startem z przekąskami</li>
                    <li>Pyszną niespodziankę na starcie</li>
                    <li>Pomiątkowa naszywka Ultra Race Roztocze</li>
                    <li>Torba Ultra Race Roztocze</li>
                    <li>Możliwość pozostawienia depozytu</li>
                    <li>Obsługę zawodów, możliwość korzystania z infrastruktury w bazie zawodów</li>
                </ul>

                <h5>Na mecie</h5>
                <ul>
                  <li>Pamiątkowy trofeum</li>
                  <li>Posiłek regeneracyjny</li>
                </ul>
                <h5>Dodatkowo pakiet możesz wzbogacić o</h5>
                <ul>
                  <li>skarpetki rowerowe URR (38 zł)</li>
                  <li>koszulka kolarską URR (319 zł)</li>
                  <li>t-shirt techniczny URR (108 zł)</li>
                </ul>
        
        </Item>
      </ListItem>
      
      
               
      <Note>*Cena zmienia się zgodnie z regulamine. <br/>Od 16.12.2024 cena pakietów wyniesie Piccolo 399zł Classico/Gigante 499zł. Zapisy trwają do 14.04.2025</Note>
      
    </>
  )
}

export default Pakiet;

import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Newsletter from '../components/Newsletter'


import styled from 'styled-components'
import Pakiet from '../components/Pakiet'

import regulamin from "../assets/regulamin_roztocze2025.pdf"
import { Link } from 'gatsby'
import Countdown from '../components/countdown'

const Title = styled.div`
    text-align: center;
    padding: 20px 5px;
    h1{
        font-size: 1.8em;
        font-weight: 100;
        span{
            font-weight: 500;
            color: #34D277;
        }
        ${({theme})=> theme.media.tablet}{
            font-size: 2em;
        }
    }

    h2{
        font-size: 1.5em;
        font-weight: 100;
        padding: 20px;
        span{
            text-decoration: underline;
        }
    }

    p{
        font-size: 1.2em;
    }

    a{
        color: black;
        font-size: 1.2em;
        text-decoration: none;
        text-transform: uppercase;
        padding: 20px;
        font-weight: lighter;
        cursor: pointer;

        :hover{
            color: #53000D;
            /* font-weight: bold; */
            text-decoration: underline;
        }
    }
 `



const ButtonZapisy = styled.button`
    margin:  10px auto;
    color: #34D277;
    font-size: 1em;
    background-color: white;
    border: 2px solid #34D277;
    /* border-radius: 15px; */
    padding: 10px 20px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.6s linear;
    width: 200px;

   
    &:hover{
         -webkit-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        -moz-box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        box-shadow: 15px 23px 16px -17px rgba(66, 68, 90, 1);
        background-color: #34D277;
        color: white;
        border: 2px solid transparent;
    }
`

const Zapisy = () => {
  return (
    <Layout>
        <Seo title="Zapisy Ultramaraton Rowerowy na Roztoczu"/>
        <Title>
            {/* <h1>Zapisy strtują 17.10.2024 od godziny 20:00. <br/>Zapisz się na Ultramaraton Rowerowy <br/><span> Ultra Race Roztocze</span></h1>  */}
            <h1>Już teraz zapisz się na Ultramaraton Rowerowy <br/><span> Ultra Race Roztocze</span></h1> 
            {/* <h1>Zapisy zamknięte </h1>  */}

            <Link href="https://b4sportonline.pl/ultra_race/">
                <ButtonZapisy>Zapisz się już dziś</ButtonZapisy>
            </Link>
            <br/>
            <Countdown/>
            <a href={regulamin} title="Regulamin Ultra Race Roztocze">Regulamin</a>
            <br/><br/>

            {/* <h2>Zapisy startują <strong>28.08.2023</strong> o godzinie 20:00</h2>
            
            <Link href="https://elektronicznezapisy.pl/event/b9m2k8l080b061v6x9v2n0q0v4c8d333.html">
                <ButtonZapisy>Zapisz się już dziś</ButtonZapisy>
            </Link>
            <br></br>
            <a href={regulamin} title="Regulamin Ultra Race Roztocze">Regulamin</a>
            <br></br><br></br>
            <Countdown/>
            {/* <p>Jeśli zapisałeś się na listę pierwszeństwa zapisu sprawdź swoją skrzynkę pocztową</p> */}
            {/* <h2>Jeśli chcesz przeżyć z nami rowerową przygode, a obawiasz się, że przeoczysz zapisy. 
                Wystarczy, że zostawisz nam swojego maila, a poinformujemy Cię o rozpoczęciu zapisów.<br></br>
                <span>Dla osób z listy przewidujemy pierwszeństwo zapisów.</span>
            </h2> */}

        </Title>
        <Pakiet/>
        
        {/* <Newsletter/> */}
        
    </Layout>
  )
}

export default Zapisy
